import React from "react";
import * as classes from "./index.module.less";
import { Link } from "gatsby";

export default () => (
  <div className={classes.container}>
    <h3>What is blocked in China?</h3>
    <p>
      Although there is no published list of sites and services blocked in
      China, internet traffic can be blocked based on DNS, IP address, URL
      keywords, and actual site content. Some of this is maintained in
      blacklists, while real-time content monitoring is also employed, both
      automated and manual. Based on China laws, any suspected illegal content
      entering the country from abroad is kept from users via connection resets
      and black-hole loops.
    </p>
    <h3>
      My website is blocked behind the Great Firewall. How can I make my site
      available in China?
    </h3>
    <p>
      If your site is blocked, there is no recourse to have it un-blocked. Your
      best solution is to get an ICP Filing with China’s Ministry of Industry
      and Information Technology (MIIT), which all sites in China are legally
      required to have. After this approval, your site will be available to all
      Chinese users.
    </p>
    <p>
      <Link to="/get-started/">Contact us</Link> for help getting an{" "}
      <Link to="/how-can-i-get-an-icp-license-for-china/">ICP Filing</Link> and
      unblocking your content in China.
      <br />
      <br />
      <br />
    </p>
  </div>
);
