import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import Layout from "components/shared/Layout";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/computer.svg";
import WebTester from "components/web-testing/WebTester";
import Button from "components/shared/Button";
import right from "images/svg_icons/right.svg";

export default ({ data }) => (
  <>
    <Meta url="/test-your-site-in-china/" />
    <Nav />
    <LargeTitle
      size="small"
      img={icon}
      black="In China"
      green="Test Your Site"
      text={
        <>
          Many of the world’s websites are blocked behind the “Great Firewall of
          China”. Is your website available to Chinese users? Click below to
          test your site’s availability in Mainland China and find out.
          <br /> <br />
          <a
            href="https://web-tester.appinchina.co/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button icon={right}>Test My Website Now</Button>
          </a>
        </>
      }
    />
    <Layout>
      <WebTester />
    </Layout>
    <CTA />
    <Footer />
  </>
);
